<template>
    <div class="home">
        <div class="banner" :style="bannerUrl">
            <p class="title">{{ appData.index_text_a && appData.index_text_a.content }}</p>
            <p class="des">{{ appData.index_text_b && appData.index_text_b.content }}</p>
        </div>
        <section class="introduce">
            <div class="container">
                <div class="introduce-item">
                    <header class="item-title">
                        <div class="text one">
                            <div class="line" style="margin-right: 10px"></div>
                            <h4>{{ appContent.about_us && appContent.about_us.title }}</h4>
                        </div>
                        <img :src="aboutUs.image" alt="" />
                    </header>
                    <div class="item-content">
                        <div class="item-text">
                            <p class="text-title">{{ aboutUs.text }}</p>
                            <p class="text-des" v-html="aboutUs.content"></p>
                        </div>
                        <div class="item-image one">
                            <img :src="aboutUs.image" alt="" />
                        </div>
                    </div>
                </div>
                <div class="introduce-item">
                    <header class="item-title">
                        <img :src="corporate_vision.image" alt="" />
                        <div class="text two">
                            <h4>{{ appContent.corporate_vision && appContent.corporate_vision.title }}</h4>
                            <div class="line" style="margin-left: 10px"></div>
                        </div>
                    </header>
                    <div class="item-content">
                        <div class="item-image two">
                            <img :src="corporate_vision.image" alt="" />
                        </div>
                        <div class="item-text">
                            <p class="text-title">{{ corporate_vision.text }}</p>
                            <p class="text-des" v-html="corporate_vision.content"></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="culture">
            <div class="container">
                <h4 class="title">{{ appContent.company_culture && appContent.company_culture.title }}</h4>
                <div class="right">
                    <ul class="right-text">
                        <li v-for="(item, index) in company_culture_list" :key="index">
                            <img :src="item.image" alt="" />
                            <div>
                                <p class="title">{{ item.text }}</p>
                                <p class="des">{{ item.content }}</p>
                            </div>
                        </li>
                    </ul>
                    <div class="right-image" :style="companyStyle"></div>
                </div>
            </div>
        </section>
        <section class="invest">
            <div class="container">
                <h4 class="invest-title">{{ appContent.Investment_areas && appContent.Investment_areas.title }}</h4>
                <div class="invest-des">{{ appContent.Investment_areas && appContent.Investment_areas.content.text }}</div>
                <el-row :gutter="10">
                    <el-col :xs="12" :sm="8" :md="6" v-for="(item, index) in investment_areas_list" :key="index">
                        <div class="item">
                            <img :src="item.image" alt="" />
                            <div class="text">
                                <p class="title">{{ item.text }}</p>
                                <p class="des">{{ item.content }}</p>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </section>
        <section class="information">
            <div class="container">
                <div class="header">
                    <h4>{{ $t('m.10033') }}</h4>
                    <span class="pointer" @click="$router.push('/information')">{{ $t('m.10034') }}<i>→</i></span>
                </div>
                <div class="content">
                    <ul>
                        <li v-for="item in article" :key="item._id">
                            <span>{{ item.publish_time | timeFormat('y.mm.dd') }}</span>
                            <div>{{ item.title }}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <home-footer />
    </div>
</template>

<script>
import HomeFooter from '../../components/homeFooter.vue';
import homeHead from '../../components/homeHead.vue';
import { mapGetters } from 'vuex';
export default {
    components: { homeHead, HomeFooter },
    computed: {
        ...mapGetters({
            appData: 'app/appData',
            appContent: 'app/comtent',
        }),
        bannerUrl() {
            let url = this.isMobile
                ? this.appData.mobile_index_image && this.appData.mobile_index_image.content
                : this.appData.pc_index_image && this.appData.pc_index_image.content;
            return {
                background: 'url(' + url + ') center no-repeat',
            };
        },
        companyStyle() {
            return {
                background: `url(${this.appContent.company_culture_image && this.appContent.company_culture_image.content}) center center / cover no-repeat`,
            };
        },
        aboutUs() {
            return (this.appContent.about_us && this.appContent.about_us.content.list[0]) || {};
        },
        corporate_vision() {
            return (this.appContent.corporate_vision && this.appContent.corporate_vision.content.list[0]) || {};
        },
        company_culture_list() {
            return (this.appContent.company_culture && this.appContent.company_culture.content.list) || [];
        },
        investment_areas_list() {
            return (this.appContent.Investment_areas && this.appContent.Investment_areas.content.list) || [];
        },
    },
    created() {
        this.getArticleList();
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    data() {
        return {
            article: [],
        };
    },
    methods: {
        getArticleList() {
            this.$api.getArticleList({ platform: this.isMobile ? 'h5' : 'pc' }).then((res) => {
                if (res.code == 200) {
                    this.article = res.data.list;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .home {
        padding-top: vv(40);
        .banner {
            height: vv(310);
            width: 100%;
            padding-top: vv(88);
            color: #ffffff;
            text-align: center;
            background: url(~@/assets/imgs/banner_m.png) 0 0 no-repeat;
            background-size: cover;
            .title {
                font-size: vv(18);
                font-weight: 500;
            }
            .des {
                font-size: vv(12);
                color: rgba($color: #fff, $alpha: 0.8);
            }
        }
        .introduce {
            .introduce-item {
                padding-top: vv(40);
                .item-title {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: vv(20);
                    .text {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        .line {
                            width: vv(62);
                            height: 2px;
                            background: #009aa4;
                            transform: translateY(vv(40));
                        }
                        h4 {
                            font-size: vv(15);
                            color: $b29;
                            font-weight: 700;
                            transform: translateY(vv(40));
                        }
                        &.two {
                            justify-content: flex-start;
                        }
                    }
                    img {
                        width: vv(211);
                        height: vv(145);
                    }
                }
                .item-content {
                    display: flex;
                    justify-content: space-between;
                    .item-image {
                        display: none;
                    }
                    .item-text {
                        width: 100%;
                        .text-title {
                            font-size: vv(15);
                            font-weight: 500;
                            color: $b29;
                            letter-spacing: 1px;
                            font-weight: 600;
                        }
                        .text-des {
                            font-size: vv(13);
                            color: $b6;
                            margin: vv(5) 0;
                            letter-spacing: 2px;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }
        .culture {
            margin-top: vv(40);
            .container {
                h4.title {
                    text-align: center;
                    font-size: vv(15);
                    color: $b29;
                    font-weight: 700;
                    margin-bottom: vv(21);
                }
                .right {
                    display: flex;
                    flex-direction: column-reverse;
                    .right-text {
                        width: 100%;
                        height: vv(256);
                        border: 1px solid #eeeeee;
                        margin-right: vv(5);
                        li {
                            height: 25%;
                            display: flex;
                            align-items: center;
                            img {
                                width: vv(37);
                                height: vv(37);
                                margin-right: vv(9);
                                margin-left: vv(10);
                            }
                            div {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                p.title {
                                    font-size: vv(15);
                                    color: $b29;
                                    margin-bottom: vv(5);
                                }
                                p.des {
                                    font-size: vv(12);
                                    color: $b6;
                                }
                            }
                        }
                    }
                    .right-image {
                        width: 100%;
                        height: vv(220);
                    }
                }
            }
        }
        .invest {
            margin-top: vv(40);
            .invest-title {
                text-align: center;
                font-size: vv(15);
                color: $b29;
                font-weight: 700;
            }
            .invest-des {
                margin-top: vv(10);
                font-size: vv(12);
                text-align: center;
                color: $b6;
                margin-bottom: vv(10);
            }

            .item {
                position: relative;
                width: 100%;
                height: vv(224);
                overflow: hidden;
                margin-bottom: 10px;
                img {
                    height: 100%;
                }
                .text {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    padding: vv(20) vv(12) 0;
                    p.title {
                        font-size: vv(15);
                        color: #ffffff;
                        margin-bottom: vv(8);
                    }
                    p.des {
                        font-size: vv(12);
                        color: rgba($color: #fff, $alpha: 0.5);
                    }
                }
            }
        }
        .information {
            margin-top: vv(40);
            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                h4 {
                    font-size: vv(15);
                    font-weight: 700;
                    color: #292f30;
                }
                span {
                    min-width: vv(75);
                    padding: 0 vv(5);
                    height: vv(20);
                    line-height: vv(20);
                    text-align: center;
                    background: #ebf0f0;
                    font-size: vv(10);
                    color: #009aa4;
                    i {
                        margin-left: vv(6);
                    }
                }
            }
            ul {
                margin-top: vv(10);
                li {
                    padding: vv(10) 0;
                    width: 100%;
                    border-bottom: 1px dotted #ccc;
                    display: flex;
                    flex-wrap: wrap;
                    span {
                        font-size: vv(12);
                        color: #656a6b;
                        margin-right: vv(15);
                    }
                    div {
                        flex: 1;
                        font-size: vv(12);
                        color: $b3;
                    }
                }
            }
        }
    }
}

@media (min-width: 769px) {
    .home {
        overflow: hidden;
        padding-top: 68px;
        .banner {
            height: 826px;
            width: 100%;
            color: #ffffff;
            background: url(~@/assets/imgs/banner_pc.png) center no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding-left: 20%;
            padding-bottom: 10%;
            .title {
                font-size: 35px;
                font-weight: 500;
            }
            .des {
                font-size: 25px;
                color: rgba($color: #fff, $alpha: 0.8);
            }
        }

        .introduce {
            display: flex;
            justify-content: center;
            padding: 0 15px;
            .introduce-item {
                padding-top: 100px;
                .item-title {
                    .text {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        font-size: 32px;
                        color: $b29;
                        margin-bottom: 40px;
                        &.two {
                            justify-content: flex-start;
                        }
                        .line {
                            width: 123px;
                            height: 2px;
                            background: #009aa4;
                        }
                    }
                    img {
                        display: none;
                    }
                }
                .item-content {
                    display: flex;
                    justify-content: space-between;
                    .item-image {
                        width: 390px;
                        height: 268px;
                        &.one {
                            box-shadow: 390px 0 0 0 #fafbfc;
                            margin-left: 10px;
                        }
                        &.two {
                            box-shadow: -390px 0 0 0 #fafbfc;
                            margin-right: 10px;
                        }
                    }
                    .item-text {
                        .text-title {
                            font-size: 22px;
                            font-weight: normal;
                            color: $b29;
                        }
                        .text-des {
                            font-size: 18px;
                            color: $b6;
                            margin: 20px 0;
                        }
                    }
                }
            }
        }
        .culture {
            margin-top: 100px;
            display: flex;
            justify-content: center;
            padding: 0 15px;
            .container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                h4.title {
                    flex: 1;
                    font-size: 32px;
                    color: $b29;
                    margin-right: 30px;
                    text-align: center;
                }
                .right {
                    display: flex;
                    align-items: center;
                    .right-text {
                        width: 300px;
                        height: 440px;
                        border: 2px solid #eeeeee;
                        margin-right: 10px;
                        li {
                            height: 25%;
                            display: flex;
                            align-items: center;
                            img {
                                width: 52px;
                                height: 52px;
                                margin-right: 30px;
                                margin-left: 20px;
                            }
                            div {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                p.title {
                                    font-size: 22px;
                                    color: $b29;
                                    margin-bottom: 10px;
                                }
                                p.des {
                                    font-size: 18px;
                                    color: $b6;
                                }
                            }
                        }
                    }
                    .right-image {
                        width: 300px;
                        height: 440px;
                    }
                }
            }
        }
        .invest {
            margin-top: 100px;
            display: flex;
            justify-content: center;
            padding: 0 15px;
            .invest-title {
                text-align: center;
                font-size: 32px;
                color: $b29;
            }
            .invest-des {
                margin-top: 20px;
                height: 72px;
                text-align: center;
                font-size: 18px;
                color: $b6;
            }

            .item {
                position: relative;
                width: 100%;
                height: 378px;
                overflow: hidden;
                margin-bottom: 10px;
                &:hover {
                    .text {
                        top: 40px;
                    }
                }
                img {
                    height: 100%;
                }
                .text {
                    position: absolute;
                    top: 300px;
                    left: 0;
                    right: 0;
                    padding: 20px;
                    transition: all 1s;
                    p.title {
                        font-size: 22px;
                        color: #ffffff;
                        margin-bottom: 30px;
                        line-height: 1.2;
                    }
                    p.des {
                        font-size: 18px;
                        line-height: 1.2;
                        color: rgba($color: #fff, $alpha: 0.5);
                    }
                }
            }
        }
        .information {
            display: flex;
            justify-content: center;
            margin-top: 100px;
            padding: 0 15px;

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                h4 {
                    font-size: 32px;
                    color: #292f30;
                }
                span {
                    width: 150px;
                    height: 36px;
                    line-height: 36px;
                    text-align: center;
                    background: #ebf0f0;
                    font-size: 14px;
                    color: #009aa4;
                    i {
                        margin-left: 15px;
                    }
                }
            }
            ul {
                margin-top: 40px;
                li {
                    padding: 20px 0;
                    width: 100%;
                    border-bottom: 1px dotted #ccc;
                    display: flex;
                    align-items: center;
                    span {
                        width: 103px;
                        font-size: 16px;
                        color: #656a6b;
                    }
                    div {
                        flex: 1;
                        font-size: 18px;
                        color: $b3;
                    }
                }
            }
        }
        .container {
            width: 100%;
        }
    }
}

@media (min-width: 992px) {
    .home {
        .culture {
            .container {
                .right {
                    .right-text {
                        width: 350px;
                    }
                    .right-image {
                        width: 350px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .home {
        .banner {
            .title {
                font-size: 48px;
                font-weight: 500;
            }
            .des {
                font-size: 32px;
                color: rgba($color: #fff, $alpha: 0.8);
            }
        }
        .container {
            width: 1200px;
        }
        .introduce {
            .introduce-item {
                .item-content {
                    .item-image {
                        &.one {
                            margin-left: 0;
                        }
                        &.two {
                            margin-right: 0;
                        }
                    }
                    .item-text {
                        width: 735px;
                    }
                }
            }
        }
        .culture {
            padding: 0;
            .container {
                .right {
                    .right-text {
                        width: 390px;
                    }
                    .right-image {
                        width: 390px;
                    }
                }
            }
        }
        .invest {
            padding: 0;
        }
        .information {
            padding: 0;
        }
    }
}
</style>
